import { useLocation, useNavigate } from "react-router-dom";
import { siteName, storage } from "../functions/O3developer";
import SideBar from './SideBar'
import Styles from './Styles.module.css';

function Header () {
  let location = useLocation();
  const navigate = useNavigate();
  const logOut = () => {      
    if(storage("remove", "data", null, "localStorage")) {
      return navigate("/login")
    }
}

  return (
    <div>

      {/* Fixed Header On Scroll */}

          <div className='header header-fixed header-logo-center header-auto-show' hidden={ location.pathname !== "/" && true}>
        <a href={void(0)} className='header-title'>{siteName} </a>
        <a href={void(0)} className='header-icon header-icon-1'><i className='fas fa-chevron-left'></i></a>
        <a href="#" data-menu='menu-main' className='header-icon header-icon-4'><i className='fas fa-bars'></i></a>

      </div>
      
      
      <div className={'header header-demo header-logo-center mb-3 shadow-l ' + Styles.headerHeight}  hidden={ location.pathname !== "/" && true}>
        <a href={void(0)} className='header-logo'>O3<span className='color-highlight'>Developers</span></a>
        <a href={void(0)} className='header-icon header-icon-4'><i className='fas fa-envelope'></i><span className='badge bg-blue-dark'>0</span></a>
        {/* <a href={void(0)} onClick={logOut} className='header-icon header-icon-4'><i className="fas fa-power-off" style={{ color: '#db0f00' }}></i></a> */}
        {/* <a href='#' className='header-icon header-icon-3'><i className='fas fa-headset color-red-light'></i></a> */}
        <a href="#" data-menu='menu-main' className='header-icon header-icon-1'><i className='fas fa-bars'></i></a>
      </div> 



  
      <SideBar/>
    </div>
  )
}

export default Header
