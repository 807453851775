import axios from 'axios';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../components/dashboard/VirtualAccount.module.css'
import LoadingModal from '../../components/modals/LoadingModal';
import { ToastContainer } from 'react-toastify';
import { baseURL, siteLogo, storage, toaster, user } from '../../functions/O3developer';
import error from '../../json/error.json';
import { loadingReducer } from '../../reducers/loading-reducer';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CopyToClipboard from 'react-copy-to-clipboard';
import PageTopBar from '../../components/PageTopBar';


function InstantTransfer () {

    const navigate = useNavigate();
    async function load() {
      dispatch({type: 'start'});      
      
        await axios.post(baseURL + "/fund-wallet/virtual-account",  {
        }, {    
          headers:{
              'Authorization':`Bearer ${user().token}`,
              'Content-Type': 'application/json'
          }, 
        }).then((response) => {
            // console.log(response.data);
        if (response.data.message == "Verification required" || response.data.message == "Invalid BVN") {
              dispatch({type: 'stop'});
              return navigate("/kyc/verification");

        } else if (response.data.status == "success" && response.data.verification == true) { 
            setAccounts(response.data.data);
            setCharge(response.data.charge);
            setChargeType(response.data.chargeType);
            dispatch({type: 'stop'});

        } else if (response.data.status == "success" && response.data.verification == false) {
            
          return navigate("/kyc/verification");

        } else if(response.data.status == "failed" && response.data.message !== "") {            
            return toaster("error", response.data.message);
        } else {
          return toaster("error", error.loading.excemption);    
        }

        }).catch((e)=>{
          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }

            return toaster("error", error.loading.excemption);
        })
    }




    const [accounts, setAccounts] = useState([]);
    const [charge, setCharge] = useState('');
    const [chargeType, setChargeType] = useState('');
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => { load(); return () => axios.CancelToken.source(); }, []);


    function AccountCard(props) {

      let imgBaseURL = "/dashboard/images/";
      let bankImg = siteLogo;
      const bank = props.bankName;
      if (bank == "Wema bank") {
        bankImg = imgBaseURL + "wema-bank-transfer-code.jpg";
      } else if (bank == "Moniepoint Microfinance Bank") {
        bankImg = imgBaseURL + "moniepoint-logo.svg";
      } else if (bank == "Sterling bank") {
        bankImg = imgBaseURL + "sterling_ussd.jpg";      
      } else if (bank == "Fidelity bank") {
        bankImg = imgBaseURL + "Fidelity-Bank.jpg";
      } 



      return (
        <div>
          <div className="content mb-3 mt-3">
              <div className="d-flex">
              <div className="align-self-center">
              {/* <i className="fa fa-plus-circle color-green-dark font-32 pe-3 pt-1"></i> */}    
              <span className='icon icon-s color-white rounded-sm shadow-xl'> 
                <img src={bankImg} style={{ width: '50px', borderRadius: '15%' }}/> 
              </span>

              </div>

              <div className="align-self-center" style={{ marginLeft: "10px" }}>
              <h4 className="mb-0 font-13"> 
              Account Number: {props.accountNumber}  </h4>
              <p className="mb-0 line-height-s">
                Name: {user().firstname} {user().lastname}
              </p>
              </div>



              <div className='ms-auto text-end align-self-center'>
                    <h5 className='color-theme font-12 font-700 d-block mb-n1'>

                    <CopyToClipboard text={props.accountNumber} onCopy={() => toaster("success", "Account number successfully copied to clipboard")}>
                    <span className={'badge border color-black border-green-dark ' + styles.VAcctTxt } style={{ cursor: "context-menu" }}> 
                    <i className='fa fa-copy'></i> Copy
                    </span>
                    </CopyToClipboard>


                    </h5>

              <span className={'color-green-dark font-11 ' + styles.VBank} ><b>Bank</b></span> </div>

              </div>
              <div className="divider mb-2 mt-3"></div>
              <div className="d-flex">
              <div>
              <h5 className="font-12 font-500 pt-2 mb-0"><i className="fa fa-arrow-right font-12 color-blue-dark pe-1"></i> {props.bankName} 👈</h5>
              </div>
              <div className="ms-auto">
              <h5 className="font-12 font-500 pt-2 mb-0"> {props.accountName.substr(0, 3) + '...'} </h5>
              </div>
              <div className="ms-auto">
              <h5 className="font-12 font-500 pt-2 mb-0"><i className="fa fa-check-circle font-12 color-green-dark pe-1"></i> Charge: {props.chargeType == "VAT" ? "₦" : ""}{props.charge}{props.chargeType == "FLAT" ? "%" : ""} </h5>
              </div>
              </div>
              </div>


              <div className="divider mb-0 mt-0"></div>

        </div>
      )
    }
  






  return (
    

<div>
          
      
    <PageTopBar title="Instant Funding" />

    <div className="page-title-clear"></div>
    <div className="page-content">


    <div className="card card-style" style={{ borderTopLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
    <div className="content">
      { loading ? <SkeletonTheme height={40}> <Skeleton count={2}/> </SkeletonTheme> : 
        <p style={{ color: "#393636", marginBottom: '5px' }} hidden={loading}>
        Send money 💰 to the below account number and your wallet will get credited automatically. 
        Bearing in mind that some charge will be deducted from the amount you sent. - Thanks for choosing us 🤝.
      </p> }

      { loading ? <SkeletonTheme height={30} width={150}> <Skeleton count={1}/> </SkeletonTheme> : <img src='/dashboard/images/monnify.png' /> }
    </div>
    </div>



<LoadingModal text="Please wait while fetching account number." key="load" visibility={loading}/>


<ToastContainer />

<a href={void(0)} className={"card dashboard-layout card-style mb-3 " + styles.Vcard}>



    {accounts.map((e, index) => <AccountCard 
            key={index}
            bankName={e.bank_name} 
            accountNumber={e.account_number} 
            accountName={e.account_name} 
            charge={charge} 
            chargeType={chargeType} 
    />)}



  </a>



    </div>



    </div>


  )
}

export default InstantTransfer
